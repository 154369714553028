<script lang="ts">
  export let _class = '';
  export {_class as class};
</script>

<svg class={_class} xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-hidden="true" viewBox="0 0 512 512">
  <path
    d="M298.091722,0.5 L298.091722,56.3237836 L351.349096,56.3237836 L351.349096,82.111896 L377.310119,82.111896 L377.310119,30.8188595 L429.787751,30.8188595 L429.787751,81.6924934 L487.162593,82.1082857 L487.162593,131.573348 L429.787751,131.573348 L429.787751,157.709678 L457.702023,157.709678 L457.702023,214.644005 L511.5,214.644005 L511.5,297.854306 L457.702023,297.854306 L457.702023,352.257918 L429.787751,352.257918 L429.787751,377.28397 L487.162593,377.28397 L487.162593,435.65012 L429.787751,435.65012 L429.787751,485.954245 L377.310119,485.954245 L377.310119,435.65012 L351.349096,435.65012 L351.349096,459.918905 L298.091722,459.918905 L298.091722,511.5 L215.065718,511.5 L215.065718,459.918905 L161.228482,459.918905 L161.228482,435.65012 L131.46015,435.65012 L131.46015,485.954245 L78.9960038,485.954245 L78.9960038,435.65012 L28.1255022,435.65012 L28.1255022,377.28397 L78.9960038,377.28397 L78.9960038,352.257918 L56.6915566,352.257918 L56.6915566,297.854306 L0.5,297.854306 L0.5,214.644005 L56.6915566,214.644005 L56.6915566,157.709678 L78.9960038,157.709678 L78.9960038,131.573348 L28.1255022,131.573348 L28.1255022,82.111896 L78.9960038,82.111896 L78.9960038,30.8188595 L131.46015,30.8188595 L131.46015,81.6889294 L161.228482,82.1191748 L161.228482,56.3237836 L215.065718,56.3237836 L215.065718,0.5 L298.091722,0.5 Z"
    id="Path-2"
    fill="currentColor"
  />
</svg>
